<template>
  <v-data-table
    :headers="headers"
    :items="itens"
    :items-per-page="15"
    :page="pageNumber"
    class="elevation-1 tabela-medicos"
    locale="pt-BR"
    loading-text="Carregando"
    no-data-text="Nenhum dado encontrado"
    :loading="loading"
    :server-items-length="total"
    @update:options="handleFilter"
    :footer-props="{
      'items-per-page-text': 'Médicos por página:',
      'items-per-page-all-text': 'Todos',
      'show-first-last-page': true,
      'show-current-page': true,
      'page-text': pageText,
      'items-per-page-options': [100, 200, 350, 500],
    }"
  >
    <template v-slot:[`body.prepend`]>
      <tr>
        <template v-for="(header, index) in headers">
          <th :key="index">
            <slot :name="header.value"> </slot>
          </th>
        </template>
      </tr>
    </template>

    <template v-slot:[`item.aceiteTermosUso`]="{ item }">
      {{ item.aceiteTermosUso ? "Sim" : "Não" }}
    </template>

    <template v-slot:[`item.especialidades`]="{ item }">
      <div v-for="especialidade in item.especialidades" :key="especialidade.id">
        <v-chip class="ma-1" color="teal" text-color="white" small>
          {{ especialidade.nome }}
        </v-chip>
      </div>
    </template>

    <template v-slot:[`item.id`]="{ item }">
      <v-menu offset-y v-if="medicoEditar || medicoExcluir">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small depressed color="fmq_gray" dark v-bind="attrs" v-on="on">
            Opções <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="gotToView(item.id)" v-if="medicoExibir">
            <v-list-item-title>Visualizar</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="gotTo(item.id)"
            v-if="medicoEditar && isRepresentante"
          >
            <v-list-item-title>Editar</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$emit('excluir', item)" v-if="medicoExcluir">
            <v-list-item-title>Excluir</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "MedicosSolicitantesTabela",
  props: {
    headers: {
      type: Array,
      required: true,
    },
    itens: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    pageText: {
      type: String,
      default: "-",
    },
    pageNumber: {
      type: Number,
      default: 1,
    },
  },
  data: () => ({
    formData: {
      orderBy: null,
      orderSorted: null,
      perPage: null,
      page: null,
    },
  }),
  methods: {
    handleFilter(event) {
      (this.formData.orderBy = event.sortBy[0]),
        (this.formData.orderSorted = event.sortDesc[0] ? "DESC" : "ASC"),
        (this.formData.perPage = event.itemsPerPage),
        (this.formData.page = event.page);
      this.$emit("handleFilter", this.formData);
    },
    gotTo(id) {
      this.$router.push({
        name: "MedicoSolicitanteEditar",
        params: { id: id },
      });
    },
    gotToView(id) {
      this.$router.push({
        name: "MedicoSolicitanteVisualizar",
        params: { id: id },
      });
    },
  },
  computed: {
    medicoEditar() {
      return this.$store.state.user.data.permissoes.includes(
        "medicoSolicitante.editar"
      );
    },
    medicoExibir() {
      return this.$store.state.user.data.permissoes.includes(
        "medicoSolicitante.exibir"
      );
    },
    medicoExcluir() {
      return this.$store.state.user.data.permissoes.includes(
        "medicoSolicitante.excluir"
      );
    },
    isRepresentante() {
      return this.$store.state.user.data.tipo !== "Representante";
    },
  },
};
</script>
<style lang="scss">
.tabela-medicos tbody tr:nth-of-type(even) {
  background-color: #ffffff;
}

.tabela-medicos tbody tr:nth-of-type(odd) {
  background-color: #eeeeee;
}
</style>
